import React from "react"
import Button from "@material-ui/core/Button"
import { css } from "@emotion/core"
import Typography from "@material-ui/core/Typography"
import { ArrowDropDown } from "@material-ui/icons"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { navigate } from "gatsby"
import { logout } from "../services/auth"
import { useMediaQuery, useTheme } from "@material-ui/core"
import { setUser } from "../state/actions"
import { connect } from "react-redux"
import AccountCircle from "@material-ui/icons/AccountCircle"
import { makeStyles } from "@material-ui/core/styles"
import { useAuth } from "../hooks/useAuth"

const circleStyle = css`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
  margin: 0 4px 0 8px;
`

const useStyles = makeStyles({
  loginText: {
    marginRight: 8,
  },
})

const UserMenu = ({ user, className }) => {
  const theme = useTheme()
  const classes = useStyles()
  const auth = useAuth()

  // console.log("refreshToken", auth.token)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (e, to) => {
    setAnchorEl(null)
    navigate(to)
  }

  const handleLogout = e => {
    e.preventDefault()
    setAnchorEl(null)
    logout(() => navigate(`/`), auth.setToken)
  }

  const menuItemsInitial = match =>
    match
      ? [
          {
            onClick: e => handleMenuItemClick(e, "/"),
            children: "Home",
          },
          {
            onClick: e => handleMenuItemClick(e, "/#PricingTable"),
            children: "Pricing",
          },
          {
            onClick: e => handleMenuItemClick(e, "/blog/"),
            children: "Blog",
          },
          {
            onClick: e => handleMenuItemClick(e, "/login/"),
            children: "Login",
          },
          {
            onClick: e => handleMenuItemClick(e, "/signup/"),
            children: "Sign-Up",
          },
        ]
      : [
          {
            onClick: e => handleMenuItemClick(e, "/login/"),
            children: "Login",
          },
          {
            onClick: e => handleMenuItemClick(e, "/signup/"),
            children: "Sign-Up",
          },
        ]

  const menuItemsLoggedIn = match =>
    match
      ? [
          {
            onClick: e => handleMenuItemClick(e, "/"),
            children: "Home",
          },
          {
            onClick: e => handleMenuItemClick(e, "/#PricingTable"),
            children: "Pricing",
          },
          {
            onClick: e => handleMenuItemClick(e, "/blog/"),
            children: "Blog",
          },
          {
            onClick: e => handleMenuItemClick(e, "/dashboard/"),
            children: "Dashboard",
          },
          {
            onClick: e => handleMenuItemClick(e, "/dashboard/my-account/"),
            children: "My Account",
          },
          { onClick: e => handleLogout(e), children: "Logout" },
        ]
      : [
          {
            onClick: e => handleMenuItemClick(e, "/dashboard/"),
            children: "Dashboard",
          },
          {
            onClick: e => handleMenuItemClick(e, "/dashboard/my-account/"),
            children: "My Account",
          },
          { onClick: handleLogout, children: "Logout" },
        ]

  const matchDown = useMediaQuery(theme.breakpoints.down("xs"))

  const menuItems = user?.id
    ? menuItemsLoggedIn(matchDown)
    : menuItemsInitial(matchDown)

  return (
    <div className={className}>
      {!user?.id ? (
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Button
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleClick}
            color="primary"
            size={"medium"}
          >
            <Typography variant={"body2"} className={classes.loginText}>
              Login / Sign Up
            </Typography>

            <AccountCircle />
          </Button>
        </div>
      ) : (
        <Button
          css={css`
            display: flex;
            align-items: center;
            text-transform: none;
          `}
          onClick={handleClick}
          aria-controls="fade-menu"
          aria-haspopup="true"
        >
          <Typography variant={"body2"}>
            {user.firstName} {user.lastName}
          </Typography>
          <div
            css={[circleStyle, { backgroundColor: theme.palette.primary.main }]}
          >
            <Typography
              variant={"overline"}
              css={css`
                margin: auto;
                color: ${theme.palette.primary.contrastText};
              `}
            >
              {user.firstName && user.firstName[0]}
            </Typography>
          </div>

          <ArrowDropDown
            css={css`
              font-size: 1.15rem;
              margin: 2px 0 0 0;
            `}
          />
        </Button>
      )}

      <Menu
        css={css`
          margin-top: 4px;
        `}
        id="fade-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={open}
        onClose={() => setAnchorEl(null)}
        disableScrollLock
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            {...item}
            css={css`
              min-width: 140px;
            `}
          />
        ))}
      </Menu>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    user: state.user,
  }
}

const mapDispatchToProps = {
  setUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu)
