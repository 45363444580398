import { gql } from "@apollo/client"
import { Button, Container } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import Logo from "../assets/svgs/Logo_color.svg"
import { useAuth } from "../hooks/useAuth"
import { useImperativeQuery } from "../hooks/useLazyQuery"
import useScrollFromTop from "../hooks/useScrollFromTop"
import { resetUser, setUser } from "../state/actions"
import ButtonLink from "./ButtonLink"
import UserMenu from "./UserMenu"
import Hidden from "@material-ui/core/Hidden"

const GET_USER = gql`
  {
    viewer {
      userId
      username
      firstName
      lastName
      email
    }
  }
`

const useStyles = makeStyles({
  header: {
    position: "fixed",
    width: "100%",
    backgroundColor: "#fafafa",
    zIndex: 999,
  },
  nav: {
    display: "flex",
    height: "3.75rem",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    zIndex: 889,
    padding: "8px 0",
    "&::after": {
      content: "''",
      position: "absolute",
      zIndex: 888,
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      opacity: 0,
      transition: "opacity .3s ease-in",
      boxShadow: "0 3px 10px -2px rgba(0,0,0,.16)",
    },
  },
  navRight: {
    display: "flex",
  },
  scrolled: {
    "&::after": {
      opacity: 1,
    },
  },
  logo: {
    opacity: 1,
    width: 120,
    transition: "opacity .3s ease-in",
  },
  logoHidden: {
    opacity: 0,
  },
  scrolledLogo: {
    opacity: 1,
  },
  highIndex: {
    zIndex: 9999,
  },
  userMenu: {
    marginLeft: 24
  },
  navItem: {
    alignSelf: "flex-end",
    zIndex: 9999,
    minWidth: 40
  },
})

const NavBar = ({ user, setUser, resetUser, isFrontPage, ...props }) => {
  const auth = useAuth()
  const [scrollIsTopLogo] = useScrollFromTop(130)

  // console.log({ scrollIsTopHeader, scrollIsTopLogo })
  const classes = useStyles()

  const [userIsLoading, setUserIsLoading] = useState(false)

  // const setUserCallback = ({ data }) => {
  //   setUser({
  //     ...user,
  //     id: data.viewer.userId,
  //     username: data.viewer.username,
  //     firstName: data.viewer.firstName,
  //     lastName: data.viewer.lastName,
  //     email: data.viewer.email,
  //     // billing: {
  //     //   company: customerData.billing ? customerData.billing.company : null,
  //     // },
  //   })
  // }

  const [getUserData, { loading: userLoading }] = useImperativeQuery(GET_USER)

  // const [
  //   getUserData,
  //   // { called: userCalled, data: userData, loading: userLoading, error: userError },
  // ] = useLazyQuery(GET_USER)

  //TODO: USER isLoggedIn should be updated in global state. After registration the use is not logged in directly.

  useEffect(() => {
    // console.log("useEffect <<<<<<<<<", { token: auth.token, user })

    if (auth.token && !user?.id) {
      setUserIsLoading(true)
      getUserData().then(response => {
        if (response?.data?.viewer) {
          const { data } = response
          // console.log({ user, data })

          const newUserData = {
            ...user,
            id: data.viewer.userId,
            username: data.viewer.username,
            firstName: data.viewer.firstName,
            lastName: data.viewer.lastName,
            email: data.viewer.email,
            // billing: {
            //   company: customerData.billing ? customerData.billing.company : null,
            // },
          }
          setUser(newUserData)
          setUserIsLoading(false)
        }
      })
    } else if (!auth.token && user?.id) {
      // console.log("---------->>>>>>> RESET USER <<<<<<< --------------")

      resetUser()
    }
  }, [auth, user])

  return (
    <header className={classes.header}>
      <Container maxWidth={"lg"}>
        <nav
          className={`${classes.nav} ${
            scrollIsTopLogo ? "" : classes.scrolled
          }`}
        >
          <Button component={Link} to={"/"} className={classes.highIndex}>
            <Logo
              className={`${classes.logo} ${
                isFrontPage ? classes.logoHidden : ""
              }${scrollIsTopLogo ? "" : classes.scrolledLogo}`}
            />
          </Button>
          <div className={classes.navRight}>
            <Hidden xsDown>
              <Button
                to={"/"}
                component={Link}
                color="primary"
                size="medium"
                className={classes.navItem}
              >
                Home
              </Button>
              <Button
                to={"/#PricingTable"}
                component={Link}
                color="primary"
                size="medium"
                className={classes.navItem}
              >
                Pricing
              </Button>
              <Button
                to={"/blog/"}
                component={Link}
                color="primary"
                size="medium"
                className={classes.navItem}
              >
                Blog
              </Button>
              <Button
                color="primary"
                size="medium"
                href={"https://docs.mobileui.dev"}
                className={classes.navItem}
              >
                Docs
              </Button>
            </Hidden>
            {userIsLoading ? (
              <CircularProgress />
            ) : (
              <UserMenu className={`${classes.userMenu} ${classes.highIndex}`} />
            )}
          </div>
        </nav>
      </Container>
    </header>
  )
}

const mapStateToProps = state => {
  return {
    user: state.user,
  }
}

const mapDispatchToProps = {
  setUser,
  resetUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
