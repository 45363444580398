import { useLazyQuery as apolloUseLazyQuery } from "@apollo/client/react/hooks/useLazyQuery"
import React from "react"
import { useQuery } from "@apollo/client"

export function useImperativeQuery(query, options) {

    const { refetch, loading } = useQuery(query, { skip: true, ...options });

    const imperativelyCallQuery = (variables) => {
      return refetch(variables);
    }

    return [imperativelyCallQuery, {loading: loading}];
  // const [execute, result] = apolloUseLazyQuery(query, options)
  //
  // const resolveRef = React.useRef()
  //
  // React.useEffect(() => {
  //   if (result.called && !result.loading && resolveRef.current) {
  //     resolveRef.current(result)
  //     resolveRef.current = undefined
  //   }
  // }, [result.loading, result.called])
  //
  // const queryLazily = React.useCallback(
  //   (variables, context) => {
  //     execute({ variables, context })
  //     return new Promise((resolve) => {
  //       resolveRef.current = resolve
  //     })
  //   },
  //   [execute],
  // )
  //
  // return [
  //   queryLazily,
  //   result,
  // ]
}
